import React, { MouseEvent, useEffect } from "react";
import SideNavigationItem from "./sideNavigationItem";
import "./sideNavigation.scss";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../models";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import {
  adminGetWorkshopVisibilityCookie,
  dispatchIfNeeded,
  filterGlassType,
  filterNew,
  filterNewGlass,
  filterStatus,
  filterStatusGlass,
  filterStatusWithWorkshopFilter,
  filterStatusWithWorkshopFilterGlass,
} from "../../utility";
import { IUserInfo, PhotoSource, Status, UserPermission } from "../../backend-models";
import * as AdminActions from "../../admin/actions";
import { useAuth } from "auth/authContext";
import { useStateSelector } from "../../store";
import { fetchGlassInspections, fetchRecords } from "../../actions";

const SideNavigation: React.FC = () => {
  const dispatch = useDispatch();
  const { isAuthenticated, isAdministrator } = useAuth();
  const userInfo: IUserInfo = useSelector((state: AppState) => state.userInfo);

  const hasPerm = (x) => Boolean(userInfo?.permissions?.includes(x));

  useEffect(() => {
    if (isAuthenticated) {
      dispatchIfNeeded(dispatch, fetchGlassInspections, glassInspections);
      dispatchIfNeeded(dispatch, fetchRecords, bodyInspections);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const showWorkshop =
    isAdministrator ||
    userInfo?.workshopObjectList?.length > 0 ||
    [
      UserPermission.AllData,
      UserPermission.Admin,
      UserPermission.AllDataSE,
      UserPermission.AllDataNO,
      UserPermission.AllDataFI,
      UserPermission.AllDataDK,
    ].some(hasPerm);
  const showGlass =
    isAdministrator ||
    userInfo?.glassWorkshops?.length > 0 ||
    userInfo?.permissions?.includes(UserPermission.AllData) ||
    [
      UserPermission.AllData,
      UserPermission.Admin,
      UserPermission.AllDataSE,
      UserPermission.AllDataNO,
      UserPermission.AllDataFI,
      UserPermission.AllDataDK,
    ].some(hasPerm);

  let maxWorkshopCount = userInfo?.workshopObjectList?.length ?? 0;
  maxWorkshopCount = Math.max(userInfo?.glassWorkshops?.length ?? 0, maxWorkshopCount);

  const showLoadMore =
    isAdministrator ||
    maxWorkshopCount >= 10 ||
    userInfo?.permissions?.includes(UserPermission.AllData) ||
    [
      UserPermission.AllData,
      UserPermission.Admin,
      UserPermission.AllDataSE,
      UserPermission.AllDataNO,
      UserPermission.AllDataFI,
      UserPermission.AllDataDK,
    ].some(hasPerm);
  const bodyInspections = useSelector((state: AppState) => state.records);
  const glassInspections = useStateSelector((state) => state.glassInspections);
  const history = useHistory();

  let noCount = bodyInspections && bodyInspections.records && bodyInspections.everLoaded ? null : " ";
  let glassNoCount = glassInspections && glassInspections.glassInspections && glassInspections.everLoaded ? null : " ";

  function statusCount(status: Status) {
    if (noCount) return noCount;
    let total = filterStatus(bodyInspections, status).length;
    let filtered = filterStatusWithWorkshopFilter(bodyInspections, status).length;
    if (total === filtered) {
      return total;
    }
    return `${filtered} (${total})`;
  }
  function glassStatusCount(status: Status) {
    if (glassNoCount) return glassNoCount;
    let total = filterGlassType(filterStatusGlass(glassInspections, status), PhotoSource.Customer).length;
    let filtered = filterGlassType(
      filterStatusWithWorkshopFilterGlass(glassInspections, status),
      PhotoSource.Customer
    ).length;
    if (total === filtered) {
      return total;
    }
    return `${filtered} (${total})`;
  }
  let totalNew = filterNew(bodyInspections).length;
  let filteredNew = filterNew(bodyInspections, true).length;
  let newCount = noCount ?? (totalNew === filteredNew ? totalNew : `${filteredNew} (${totalNew})`);

  // eslint-disable-next-line
  const [adminVisibilityCookie, adminVisibilityAll] = adminGetWorkshopVisibilityCookie();

  function sectionHeaderClick(e: MouseEvent) {
    const parent = (e.target as Element).parentNode as Element;
    parent.classList.toggle("is-open");
  }

  const adminMenu = (
    <>
      <div className="if panel is-expandable">
        <div className="section-header admin title" onClick={sectionHeaderClick}>
          <FormattedMessage id="section.admin" />
        </div>
        <div className="if content">
          <SideNavigationItem admin={true} icon="if icon symbol bug" text="Test" name="/test" />
          <SideNavigationItem
            admin={true}
            icon="if icon ui preview"
            text={
              <span>Workshops {!adminVisibilityAll && <span className="if icon ui warning blue admin-icon" />}</span>
            }
            name="/workshop-visibility"
          />
          <SideNavigationItem admin={true} icon="if icon ui info" text="Notifications" name="/notifications" />
          <SideNavigationItem admin={true} icon="if icon symbol chatbot" text="Feedback" name="/feedback" />
          <SideNavigationItem admin={true} icon="if icon symbol chart-bar" text="Stats" name="/stats" />
        </div>
      </div>
    </>
  );

  const workshopMenu = (
    <>
      <div className="new-inspection-container">
        <a
          href="/#/new"
          className="new-inspection-item if button secondary"
          data-cy="button-new-inspection"
          onClick={(e) => {
            history.push("/new");
            e.preventDefault();
          }}
        >
          <FormattedMessage id="link.createNewInspection" />
        </a>
      </div>

      <div className="if panel is-expandable is-open">
        <div className="section-header title" onClick={sectionHeaderClick}>
          <FormattedMessage id="section.bodyInspections" />
        </div>
        <div className="if content">
          <SideNavigationItem
            icon="if icon ui mail"
            text={<FormattedMessage id="page.inbox" />}
            messageCount={newCount}
            name="/inbox"
          />
          <SideNavigationItem
            icon="if icon ui clock"
            text={<FormattedMessage id="page.inProgress" />}
            messageCount={statusCount(Status.InProgress)}
            name="/inProgress"
          />
          <SideNavigationItem
            icon="if icon symbol flag"
            text={<FormattedMessage id="page.onHold" />}
            messageCount={statusCount(Status.OnHold)}
            name="/onHold"
          />
          <SideNavigationItem
            icon="if icon ui hourglass"
            text={<FormattedMessage id="page.open" />}
            messageCount={statusCount(Status.Open)}
            name="/open"
          />
          <SideNavigationItem
            icon="if icon symbol single-copy"
            text={<FormattedMessage id="page.archive" />}
            name="/archive"
          />
          <SideNavigationItem icon="if icon ui search" text={<FormattedMessage id="page.search" />} name="/search" />
          {window.ClientEnvironment.flags.disablingOfPhotoInspectionService && (
            <SideNavigationItem icon="if icon ui cog" text={<FormattedMessage id="page.settings" />} name="/settings" />
          )}
        </div>
      </div>
    </>
  );

  const glassTotalNew = filterGlassType(filterNewGlass(glassInspections), PhotoSource.Customer).length;
  const glassFilteredNew = filterGlassType(filterNewGlass(glassInspections, true), PhotoSource.Customer).length;
  const glassNewCount =
    glassNoCount ?? (glassTotalNew === glassFilteredNew ? glassTotalNew : `${glassFilteredNew} (${glassTotalNew})`);
  const glassArchiveCount =
    filterGlassType(filterStatusGlass(glassInspections, Status.Closed), PhotoSource.Customer).length +
    filterGlassType(glassInspections.glassInspections, PhotoSource.Unknown, PhotoSource.Workshop).length;

  const glassMenu = (
    <>
      <div className="if panel is-expandable is-open">
        <div className="section-header title" onClick={sectionHeaderClick}>
          <FormattedMessage id="section.glassInspections" />
        </div>
        <div className="if content">
          <SideNavigationItem
            icon="if icon ui mail"
            text={<FormattedMessage id="page.inbox" />}
            messageCount={glassNewCount}
            name="/glass-inbox"
          />
          <SideNavigationItem
            icon="if icon ui clock"
            text={<FormattedMessage id="page.inProgress" />}
            messageCount={glassStatusCount(Status.InProgress)}
            name="/glass-inProgress"
          />
          <SideNavigationItem
            icon="if icon symbol single-copy"
            text={<FormattedMessage id="page.archive" />}
            messageCount={glassArchiveCount}
            name="/glass-archive"
          />
          <SideNavigationItem
            icon="if icon ui search"
            text={<FormattedMessage id="page.search" />}
            name="/glass-search"
          />
        </div>
      </div>
    </>
  );

  const loadMore = (
    <div className="if panel">
      <a
        href="#"
        className="new-inspection-item admin-load-more if button secondary"
        onClick={(e) => {
          dispatch(AdminActions.fetchAllRecords());
          e.preventDefault();
        }}
      >
        Load inspections older than 2 weeks
      </a>
    </div>
  );

  return (
    <div className="sidenav-container" data-cy="nav">
      {isAuthenticated && (
        <>
          {showWorkshop && workshopMenu}
          {showGlass && glassMenu}
          {isAdministrator && adminMenu}
          {showLoadMore && loadMore}

          <div className="spacer" />
        </>
      )}
    </div>
  );
};

export default SideNavigation;
