import "./testPage.scss";
import React, { useEffect, useState } from "react";
import { reactPlugin, withAITracking } from "../../infrastructure/applicationInsights";
import { useDispatch, useSelector } from "react-redux";
import { AppState, IRecords } from "../../models";
import { addRecord, fetchRecords, newInspection } from "../../actions";
import { sendNotificationEmails } from "../actions";
import {
  Brand,
  GlassDecision,
  IGlassInspectionRequest,
  IRecord,
  IUserInfo,
  PhotoSource,
  Status,
} from "../../backend-models";
import { useHistory } from "react-router-dom";
import { dispatchIfNeeded, formatPhotoSource, formatStatus } from "../../utility";
import { TestIntakeLinkDecoder } from "./intakeLinkDecoder";
import { TestOther } from "./other";
import { TestTimeFormats } from "./timeFormats";
import { TestCleaning } from "./cleaning";
import { TestPermanentIntakeUrl } from "./permanentIntakeUrl";
import { TestLangSelect } from "./langSelect";
import { TestPrefillCheckbox } from "./prefillCheckbox";
import { TestSmsTemplate } from "./smsTemplate";
import { useAuth } from "auth/authContext";
import { Impersonate } from "./impersonate";

function regNumber() {
  return (
    "xxx"
      .split("")
      .map((_) => String.fromCharCode((Math.random() * 26) | (0 + 65)))
      .join("") +
    ((Math.random() * 900 + 100) | 0)
  );
}

function testRecordBase() {
  const names = "Alice,Lucas,Olivia,Elias,Noah,Vera,Lily,Adam,Oscar".split(",");
  const surnames = "Johansson,Andersson,Karlsson,Nilsson,Eriksson,Larsson,Olsson,Persson,Svensson".split(",");

  function choice(set) {
    return set[(Math.random() * set.length) | 0];
  }

  let name = choice(names);
  let surname = choice(surnames);
  let fullName = name + " " + surname;
  let email = (name + "." + surname + "@example.com").toLowerCase();
  return {
    customer: {
      name: fullName,
      registrationNumber: regNumber(),
      phoneNumber: "+46123456789",
      email: email,
    },
    workshopId: "PHINTEST",
    mileage: (Math.random() * 10000 + 1000) | 0,
    description: "Autogenerated new case, no photos",
    claimNumber: null,
  } as IRecord;
}

const TestPage: React.FC = () => {
  const history = useHistory();
  const { isAdministrator } = useAuth();
  const userInfo: IUserInfo = useSelector((state: AppState) => state.userInfo);
  if (!isAdministrator) history.push("/");

  const dispatch = useDispatch();
  const records: IRecords = useSelector((state: AppState) => state.records);

  useEffect(() => {
    dispatchIfNeeded(dispatch, fetchRecords, records);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let [state, setState] = useState({
    type: "open",
    prefill: false,
    lang: "SE",
    brand: "If",
  });

  let openRecords = records.records.filter((r) => r.status === Status.Open && r.workshopId === "PHINTEST");
  let newestOpenTestRecord = openRecords[openRecords.length - 1];
  let newRecords = records.records.filter((r) => r.status === Status.New && r.workshopId === "PHINTEST");
  let newestNewTestRecord = newRecords[newRecords.length - 1];

  let openTestRecordUrl = "";
  if (newestOpenTestRecord) {
    openTestRecordUrl = newestOpenTestRecord.shortIntakeUrl + "?__test";
    if (state.prefill) openTestRecordUrl += "&prefill";
    openTestRecordUrl = openTestRecordUrl.replace("lang=SE", "lang=" + state.lang);
  }

  const tenMinsAgo = () => new Date(new Date().getTime() - 1000 * 60 * 10);

  function createCase(brand, createdBy) {
    brand = Brand[brand];
    let record: IRecord = {
      ...testRecordBase(),
      status: Status.Open,
      brand: brand,
      createdBy,
    };

    createdBy === "IPS" || createdBy === "BluePilot"
      ? (record.intakeSmsSentDate = tenMinsAgo().toISOString())
      : (record.dateOfIntake = tenMinsAgo().toISOString());

    if (state.type === "new") {
      record.status = Status.New;
    } else if (state.type === "open") {
      record.status = Status.Open;
    }
    dispatch(addRecord(record));
  }

  function createGlassCase(brand, photoSource: PhotoSource) {
    let glassInspectionReq: IGlassInspectionRequest = {
      ...testRecordBase(),
      brand: brand,
      workshopDecision: GlassDecision.Repair,
      aiDecision: GlassDecision.Replace,
      registrationNumber: regNumber(),
      coordinates: '{"foo": "bar"}',
      photoSource,
      status: Status.Closed,
      claimNumber: "23.0000000",
      claimDate: "2023-07-01",
      locationOfIncident: "location of incident",
    };
    if (photoSource === PhotoSource.Customer) {
      glassInspectionReq = {
        ...glassInspectionReq,
        status: Status.New,
        workshopDecision: GlassDecision.NotAvailable,
        aiDecision: GlassDecision.NotAvailable,
      };
    }
    dispatch(newInspection(glassInspectionReq));
  }

  function TestButton(brand, createdBy = null) {
    const cyCreatedBy = createdBy ?? "me";
    createdBy = createdBy ?? userInfo.name;
    return (
      <button
        className="if button secondary"
        onClick={() => createCase(brand, createdBy)}
        data-cy={`buttonCreateCase-${brand}-${cyCreatedBy}`.toLowerCase()}
      >
        {brand}
        {cyCreatedBy !== "me" && ", " + createdBy}
      </button>
    );
  }

  function GlassTestButton(brand: Brand, photoSource: PhotoSource) {
    return (
      <button
        className="if button secondary"
        onClick={() => createGlassCase(brand, photoSource)}
        data-cy={`buttonCreateCase-${brand}`.toLowerCase()}
      >
        {formatStatus(photoSource === PhotoSource.Customer ? Status.New : Status.Closed)},{" "}
        {formatPhotoSource(photoSource)}
      </button>
    );
  }

  return (
    <div className="padded-block test-page">
      <h1>Test page</h1>
      <div
        style={{
          margin: "2em 0px",
          textAlign: "left",
          fontSize: "0.8em",
          lineHeight: "1.3em",
        }}
      >
        If you open this page after navigating to other pages, it will already show "Newest Inbox/Open record", these
        are as the name implies, just the newest records with Workshop=PHINTEST.
        <br />
        <br />
        Permanent link doesn't include ID, don't expire, can be bookmarked and used for testing. You can submit the
        intake but a new record will not be created. Photos are ignored (not saved) with the permanent link.
        <br />
        <br />
        Ctrl+Alt+Click on a record in Open will take you to intake of that record
      </div>
      <h2>Create body inspection</h2>
      <div className="if input-wrapper">
        <select
          className="if dropdown "
          onChange={(e) => setState({ ...state, type: e.target.value })}
          value={state.type}
          data-cy="caseType"
        >
          <option value="open" data-cy="caseTypeOpen">
            Open
          </option>
          <option value="new" data-cy="caseTypeNew">
            Inbox
          </option>
        </select>
      </div>
      <br />
      {TestButton("If", "IPS")}
      {TestButton("If", "BluePilot")}
      {TestButton("If")}
      <br />
      {TestButton("Volvia")}
      {TestButton("Renault")}
      {TestButton("Volkswagen")}
      <br />
      {TestButton("Audi")}
      {TestButton("Kia")}
      {TestButton("Nissan")}
      {TestButton("Ford")}
      <h2>Create glass inspection (Volvia)</h2>
      <br />
      {GlassTestButton(Brand.Volvia, PhotoSource.Workshop)}
      {GlassTestButton(Brand.Volvia, PhotoSource.Customer)}
      <br />
      <br />
      <hr />
      <script data-cy="newestNewTestRecord" data-id={newestNewTestRecord?.recordId} />
      <br />
      <h2>Newest Inbox record</h2>
      {/* eslint-disable-next-line*/}
      <a
        className="if button primary"
        onClick={() => history.push("/details/" + newestNewTestRecord?.recordId)}
        data-cy="buttonDetails"
      >
        Details &nbsp;
        <span style={{ position: "relative", top: "0.1em" }} className="if icon white ui arrow-right" />
      </a>
      {"/details/" + newestNewTestRecord?.recordId}
      <script data-cy="newestOpenTestRecord" data-id={newestOpenTestRecord?.recordId} data-url={openTestRecordUrl} />
      <br />
      <h2>Newest Open record</h2>
      <TestLangSelect value={state.lang} onChange={(v) => setState({ ...state, lang: v })} name="open" />
      <TestPrefillCheckbox value={state.prefill} onChange={(v) => setState({ ...state, prefill: v })} name="open" />
      <br />
      {/* eslint-disable-next-line*/}
      <a className="if button primary" onClick={() => history.push(openTestRecordUrl)} target="_blank">
        Intake &nbsp;
        <span style={{ position: "relative", top: "0.1em" }} className="if icon white ui arrow-right" />
      </a>
      {openTestRecordUrl}
      <TestPermanentIntakeUrl />
      <TestIntakeLinkDecoder />
      <TestCleaning />
      <TestTimeFormats />
      <TestOther />
      <TestSmsTemplate />
      <a className="if button primary" onClick={() => dispatch(sendNotificationEmails())} target="_blank">
        Manually SendNotificationEmails
      </a>
      <Impersonate />
    </div>
  );
};

export default withAITracking(reactPlugin, TestPage);
